import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, CardHeader, CardContent, Stack, Button, Box, Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { Icon } from "@iconify/react";
import StatusBTN from "../../components/StatusBTN";
import TeacherManagementService from "../../services/TeacherManagementService";
import { formatDate } from "../../utils/formatTime";
import { toast } from "react-toastify";

const ViewStudentManagement = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  // Fetch data function moved outside of useEffect
  const fetchData = async () => {
    try {
      const response = await TeacherManagementService.getById({ id: id });
      setData(response?.data?.data);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const invitationAcceptButton = async (courseId, accept ,enrollId ) => {
    console.log(enrollId)
    try {
      const payload = {
        courseId: courseId,
        id: id,
        enrollmentStatus: accept,
        enrollId:enrollId,
      };

      const response =  await TeacherManagementService.Invitation(payload);
      toast.success(response?.data?.message);
      await fetchData();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
      <Helmet>
        <title>Student Management</title>
      </Helmet>
      <CardLayout>
        <CardHeader/>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                <CardHeader
                  title="Student Detail"
                  style={{ textAlign: "center", color: "#D95323" }}
                />
                <CardContent>
                  <StatusBTN
                    status={!data?.isBlocked}
                    sx={{
                      display: "inline",
                      float: "right",
                    }}
                  />
                  <Stack spacing={2}>
                    <Typography variant="h6">Name</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.name || ""}
                    </Typography>
                    <Divider></Divider>
                    <Typography variant="h6">Email</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.email || ""}
                    </Typography>
                    <Divider></Divider>
                    <Typography variant="h6">Registration Date</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {formatDate(data?.createdAt) || "NA"}
                    </Typography>
                    <Divider></Divider>

                    {data.groupData?.length > 0? (
                      <>
                      <Typography variant="h6">Groups</Typography>
                      <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                        {data.groupData?.length > 0 ? (
                          data.groupData.map((group, taskIndex) => (
                            <>
                              {group?.title || ""}
                              {taskIndex < data.groupData.length - 1 && ", "}
                            </>
                          ))
                        ) : (
                          ""
                        )}
                      </Typography>
                    </>
                     ):''}

                    {data.courseData?.length > 0? (
                      <>
                        <Typography variant="h6">Courses Enrolled</Typography>
                        <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                          {data.courseData?.length > 0 ? (
                            data.courseData.map((course, taskIndex) => (
                              <>
                                {course?.courseId?.name || ""}
                                {taskIndex < data.courseData.length - 1 && ", "}
                              </>
                            ))
                          ) : (
                            "No tasks available"
                          )}
                        </Typography>
                      </>
                     ):''}

                    {data.courseInvites?.length > 0 && (
                      <>
                        <Divider></Divider>
                        <Typography variant="h6">Enrollment Requests</Typography>
                      </>
                    )}

                    {data.courseInvites?.length > 0
                      ? data.courseInvites.map((course, taskIndex) => (
                          <Grid item xs={12} md={12} key={taskIndex}>
                            <Grid item xs={12} md={12}>
                              <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                                <b>{course?.courseId?.name || ""}</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                                {course?.courseId?.description || ""}
                              </Typography>
                            </Grid>
                            <Stack spacing={1} mt={1} direction="row" alignItems="center">
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => invitationAcceptButton(course.courseId._id, "accepted" ,course._id)}
                              >
                                Accept
                              </Button>

                              <Button
                                type="button"
                                variant="outlined"
                                onClick={() => invitationAcceptButton(course.courseId._id, "rejected" ,course._id)}
                              >
                                Reject
                              </Button>
                            </Stack>
                            {taskIndex < data.courseInvites.length - 1 && <Divider />}
                          </Grid>
                        ))
                      : ""}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default ViewStudentManagement;
