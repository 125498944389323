import axios from 'axios';
import { API_BASE } from '../utils/constants';
import AxiosInterceptor from "../utils/AxiosInterceptor";
const API_URL = API_BASE + "adminAccess/";

const apiAuth = {
  resetPassword: (value) =>  AxiosInterceptor().post(API_BASE + "/update-password", value),
  getSetting: (value) => AxiosInterceptor().get(API_BASE + "/get-global-setting", value),  
  updateSetting : (value) => AxiosInterceptor().post(API_BASE + "/update-global-setting", value),
};

export default apiAuth;
