import { Helmet } from "react-helmet-async";
import CardLayout from "../../layouts/CardLayout";
import React, { useEffect, useState } from "react";
import errorHandler from "../../utils/errorHandler";
import { useNavigate, useParams } from "react-router-dom";
import TeacherManagementService from "../../services/TeacherManagementService";
import { Grid, Card, CardHeader, CardContent, Box, FormHelperText, InputLabel,Divider, Button, Stack } from "@mui/material";

const ViewStudentGrades = () => {
  const { id } = useParams();
  const [scoresData, setScoresData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await TeacherManagementService.ViewScoreStudent({ id });
        console.log(response?.data?.data, "response data");
        setScoresData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  const handleEdit = () => {
    navigate(`/teacher-management/edit/${id}`);
  };

  const handleViewTask = (studentId, courseId, taskId) => {
    navigate(`/student-management/student-liview-report-per-task/${studentId}/${courseId}/${taskId}`);
  };
  
  return (
    <Box>
      <Helmet>
        <title>Student Grade</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`Student Grades`} />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                <CardContent>
                  <Grid container spacing={3}>
                    {scoresData.length > 0 ? (
                      scoresData.map((course, courseIndex) => (
                        <React.Fragment key={courseIndex}>
                          <Grid item xs={12}>
                            <InputLabel>Teacher Name: {course.teacherName}</InputLabel>
                            <InputLabel>Course: {course.courseName}</InputLabel>
                          </Grid>
                          {course.tasks?.length > 0 ? (
                            course.tasks.map((task, taskIndex) => (
                              <Grid item xs={12} md={6} key={taskIndex}>
                                <div className="view-score-list">
                                    {task.taskName}: 
                                    <span>{task?.score}{task?.score == "Not Attempted" ? '' :'%'}</span>

                                    <Button 
                                      variant="contained" 
                                      onClick={() => handleViewTask(id, course.courseId, task.taskId)}
                                    >
                                      View Task
                                    </Button>
                                </div>                               
                              </Grid>                             
                            ))
                          ) : (
                            <Grid item xs={12}>
                              <InputLabel>No tasks available</InputLabel>
                            </Grid>
                          )}
                          
                        </React.Fragment>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <InputLabel>No grades available</InputLabel>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default ViewStudentGrades;
