import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Link as MLink,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Iconify from "../../components/iconify";
import apiAuth from "../../services/authService";
import { login } from "../../features/AuthSlice";
import errorHandler from "../../utils/errorHandler";
import palette from "../../theme/palette";
import { styled } from "@mui/material/styles";
import { MESSAGE } from "../../utils/validationMessage";
import { EMAIL_REGEX } from "../../utils/constants";

const LoginSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email(MESSAGE.EMAIL).matches(EMAIL_REGEX, MESSAGE.EMAIL),
  password: Yup.string().required("Password is required"),
});

const ForgotLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  textAlign: "center",
  display: "block",
  color: palette.error.main,
  marginTop: "12px",
}));

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const res = await apiAuth.login(values);
        dispatch(login(res.data.data.auth));
        navigate("/dashboard", { replace: true });
      } catch (err) {
        console.log(err)
        errorHandler(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>

      <Form autoComplete="off" noValidate onSubmit={handleSubmit} className={"loginPageDesign"}>
        <Stack spacing={3}>
          <Typography variant="h3" sx={{ mb: 2 }} align="center">Sign In</Typography>
          <TextField
            name="email"
            label="Email address"
            placeholder="Enter email address"
            focused={true}
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            name="password"
            label="Password"
            placeholder="Enter password"
            focused={true}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={
                        showPassword
                          ? "eva:eye-fill"
                          : "eva:eye-off-fill"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...getFieldProps("password")}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ my: 2 }}
        >
            Login
        </LoadingButton>

        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ my: 2 }}
        >
          <MLink
            variant="subtitle2"
            underline="hover"
            to="/forgot"
            as={Link}
            color={palette.primary.main}
          >
            Forgot password?
          </MLink>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default Login;
