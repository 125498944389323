import AxiosInterceptor from "../utils/AxiosInterceptor";

const taskAndQuestionnair = {
  edit: (value) => AxiosInterceptor().post("/update-one-task", value),
  getById: (value) => AxiosInterceptor().post("/get-one-task", value),
  getAll: (value) => AxiosInterceptor().post("/get-all-tasks", value),
  add: (value) => AxiosInterceptor().post("/addTask", value),
  delete: (value) => AxiosInterceptor().post("delete-task", value),
  getDropdownLanguages: (value) =>
    AxiosInterceptor().post("/get-all-course-entity-without-pagination", value),
  getPresignedUrl: (value) => AxiosInterceptor().post("get-presigned-url-public", value),
  viewSignedUrlVal: (value) => AxiosInterceptor().post("view-presigned-url-public", value),
  taskList: (value) => AxiosInterceptor().get(`/view-per-task-student-score/${value.id}`, ),
  viewReportPerTaskForStudent: (value) => AxiosInterceptor().post(`/view-report-per-task-for-student`,value ),
};

export default taskAndQuestionnair;