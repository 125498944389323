import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Card, Typography, CardHeader, CardContent, Stack, Button, Box, InputLabel, FilledInput ,Divider} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import CoursesManagementList from "../../services/coursesManagementSerives"
import { Icon } from "@iconify/react";

const ViewCourceManagement = () => {
  const { id } = useParams();
  const [CourceManagement, setCourceManagement] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CoursesManagementList.getById({ id: id });
        setCourceManagement(response.data.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  const handleEdit = () => {
    navigate(`/courses-management/edit/${id}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Helmet>
        <title>Courses Management</title>
      </Helmet>
      <CardLayout>
        <Card>
          <CardHeader title="Courses Management View" />
          <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
                <Typography variant="h6">Creator Name :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                  {CourceManagement?.teacherId?.name}
                </Typography>
                <Divider></Divider>                    
            </Grid>

            <Grid item xs={6}>
                <Typography variant="h6">Creator Email :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                  {CourceManagement?.teacherId?.email}
                </Typography>
                <Divider></Divider>              
            </Grid>

            <Grid item xs={6}>
                <Typography variant="h6">Language :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                  {CourceManagement?.languageId?.title}
                </Typography>
                <Divider></Divider>              
            </Grid>

            <Grid item xs={6}>
                <Typography variant="h6">Institution Type :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                  {CourceManagement?.institutionType}
                </Typography>
                <Divider></Divider>              
            </Grid>

            <Grid item xs={6}>
                <Typography variant="h6">Year :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                  {CourceManagement?.year}
                </Typography>
                <Divider></Divider>              
            </Grid>


            <Grid item xs={6}>
                <Typography variant="h6">Course Level :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                  {CourceManagement?.courseLevelId?.title}
                </Typography>
                <Divider></Divider>              
            </Grid>
            
            {/* Condition */}
            {CourceManagement?.semesterId && CourceManagement?.institutionId?.title? 
            <>
            <Grid item xs={6}>
                <Typography variant="h6">Semester :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                  {CourceManagement?.semesterId?.title}
                </Typography>
                <Divider></Divider>              
            </Grid>
          
            <Grid item xs={6}>
                <Typography variant="h6">Institution :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                {CourceManagement?.institutionId?.title}
                </Typography>
                <Divider></Divider>              
            </Grid>          
            </>
          :''}

          {CourceManagement?.courseLevelId?.title ==="Other" ?
            <Grid item xs={12}>
                <Typography variant="h6">Other Course Level Description :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                 
                  {CourceManagement?.otherCourseLevelDescription}
                </Typography>
                <Divider></Divider>              
            </Grid>
          :null}

          {CourceManagement?.institutionId?.title ==="Other" ?
            <Grid item xs={12}>
                <Typography variant="h6">Other Institution Description :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                  {CourceManagement?.otherInstitutionDescription}
                </Typography>
                <Divider></Divider>              
            </Grid>
          :null}

          {/* Condition */}

            <Grid item xs={12}>
                <Typography variant="h6">Course Name :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                  {CourceManagement?.name}
                </Typography>
                <Divider></Divider>              
            </Grid>    

            <Grid item xs={12}>
                <Typography variant="h6">Description :</Typography>
                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                  {CourceManagement?.description}
                </Typography>
                <Divider></Divider>              
            </Grid>    
            
            {CourceManagement?.tasks && CourceManagement.tasks.length > 0 && (
              CourceManagement.tasks.map((task, index) => (
                task?.cutOff ?
              <>
              <Grid item xs={12}>
                <Typography variant="h6">Task : {index+1}</Typography>
              </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">Task Name:</Typography>
                  <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>{task?.taskId?.title}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">Task CutOff:</Typography>
                  <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>{task?.cutOff}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">Start Date:</Typography>
                  <Typography variant="body1">{new Date(task?.startDate).toLocaleDateString("en-US")}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">End Date:</Typography>
                  <Typography variant="body1">{new Date(task?.endDate).toLocaleDateString("en-US")}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">Due Date:</Typography>
                  <Typography variant="body1">{new Date(task?.dueDate).toLocaleDateString("en-US")}</Typography>
                </Grid>
                <Grid item xs={12}>
                <Divider></Divider>
                </Grid>
                </>
                : ''
            )))}
            <Divider></Divider>
          </Grid>

            <Stack
              spacing={2}
              mt={3}
              direction="row"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                startIcon={<Icon icon="fa-solid:edit" />}
                onClick={handleEdit}
                sx={{ textTransform: 'none' }}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{ textTransform: 'none' }}
              >
                Cancel
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </CardLayout>
    </Box>
  );
};

export default ViewCourceManagement;
