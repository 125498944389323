import { Helmet } from "react-helmet-async";
import CardLayout from "../../layouts/CardLayout";
import React, { useEffect, useState } from "react";
import errorHandler from "../../utils/errorHandler";
import { useParams } from "react-router-dom";
import TeacherManagementService from "../../services/TeacherManagementService";
import { Grid, Card, CardHeader, CardContent, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material";

const StudentliviewReportPerTask = () => {
    const { studentId, courseId, taskId } = useParams();
    const [data, setData] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            const payload = {
                studentId: studentId,
                courseId: courseId,
                taskId: taskId,
            };
            try {
                const response = await TeacherManagementService.liviewReportPerTask(payload);
                console.log(response?.data?.data, "response data");
                setData(response?.data?.data);
            } catch (error) {
                errorHandler(error);
            }
        };
        fetchData();
    }, [studentId, courseId, taskId]);
    
    return (
        <Box>
            <Helmet>
                <title>Student Report Per Task</title>
            </Helmet>
            <CardLayout>
                <CardHeader title={`Student Report Per Task`} />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                                <CardContent>
                                    {data.length > 0 ? (
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No.</TableCell>
                                                        <TableCell>Audio</TableCell>
                                                        <TableCell>Options</TableCell>
                                                        <TableCell>Correct Answer</TableCell>
                                                        <TableCell>Your Answer</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.map((item, itemIndex) => (
                                                        item?.questionData?.questions.map((question, questionIndex) => (
                                                            <TableRow key={`${itemIndex}-${questionIndex}`}>
                                                                <TableCell>{itemIndex + 1}</TableCell>
                                                                <TableCell>{question.audio}</TableCell>
                                                                <TableCell>{item.options.join(', ')}</TableCell>  {/* Access options from the main object */}
                                                                <TableCell>{question.options[parseInt(question.correctAnswer)]}</TableCell>
                                                                <TableCell>{item.correct ? "Correct" : "Incorrect"}</TableCell>
                                                            </TableRow>
                                                        ))
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ) : (
                                        <div>No grades available</div>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardLayout>
        </Box>
    );
};

export default StudentliviewReportPerTask;