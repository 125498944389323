import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, CardHeader, CardContent, Stack, Button, Box, Divider} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { Icon } from "@iconify/react";
import StatusBTN from "../../components/StatusBTN";
import TeacherManagementService from "../../services/TeacherManagementService";
import { formatDate } from "../../utils/formatTime";

const ViewTeacherManagement = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await TeacherManagementService.getById({ id: id });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  const handleEdit = () => {
    navigate(`/teacher-management/edit/${id}`);
  };

  return (
    <Box>
      <Helmet>
        <title>Teacher Management</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Teacher View" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                <CardHeader
                  title="Teacher Detail"
                  style={{ textAlign: "center", color:"#D95323" }}
                />
                <CardContent>

                  <StatusBTN
                    status={!data?.isBlocked}
                    sx={{
                      display: "inline",
                      float: "right",
                      display: "inline",
                    }}
                  />
                  <Stack spacing={2}>
                    <Typography variant="h6">Name</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.name || ""}
                    </Typography>
                    <Divider></Divider>
                    <Typography variant="h6">Email</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.email || ""}
                    </Typography>

                    <Divider></Divider>

                    {data.courseData?.length > 0? (
                      <>
                        <Typography variant="h6">Courses Enrolled</Typography>
                        <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                          {data.courseData?.length > 0 ? (
                            data.courseData?.map((course, taskIndex) => (
                              <>
                                <Link to={`/courses-management/edit/${course?._id}`}>{course?.name || ""}</Link>
                                {taskIndex < data.courseData.length - 1 && ", "}
                              </>
                            ))
                          ) : (
                            "No tasks available"
                          )}
                        </Typography>
                      </>
                     ):''}

                    <Typography variant="h6">Registration Date</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {formatDate(data?.createdAt) || 'NA'}
                    </Typography>
                    <Divider></Divider>

                  </Stack>
                  <Stack
                    spacing={2}
                    mt={3}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button
                      type="button"
                      variant="contained"
                      startIcon={<Icon icon="fa-solid:save" />}
                      onClick={handleEdit}
                    >
                      Edit
                    </Button>
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default ViewTeacherManagement;
