import { LoadingButton } from '@mui/lab'
import { Button, Stack } from '@mui/material'
import React from 'react'
import { Icon } from "@iconify/react";
import { useNavigate } from 'react-router-dom';

const SaveButton = ({ loading, id  ,type , deleteData }) => {

    const navigate = useNavigate()

    return (
        <Stack
            spacing={2}
            mt={3}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
        >
            <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                startIcon={<Icon icon="fa-solid:save" />}
            >
                {id ? "Update" : "Add"}
            </LoadingButton>
            <Button
                type="button"
                variant="outlined"
                className='button-outlined'
                onClick={() => navigate(-1)}
            >
                Cancel
            </Button>
            {type ==="delete" ?
            <Button
                    type="button"
                    variant="outlined"
                    className='button-outlined'
                    onClick={() => deleteData(id)}
                >
                Delete
            </Button>
            :null}
        </Stack>
    )
}

export default SaveButton